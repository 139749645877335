<template>
  <div class="flex justify-center min-h-screen bg-gray-100">
    <div
      class="w-[45%] h-3/5 bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
      v-if="eventData"
    >
      <div class="w-full md:w-1/2">
        <!-- Left Section -->
        <div
          class="flex flex-col md:flex-row p-4 rounded-lg shadow-lg space-y-4 md:space-y-0 md:space-x-4"
          style="background-color: #ecf5ff"
        >
          <div class="w-full md:w-1/3">
            <!-- Left Subsection 1 -->
            <div>
              <img
                src="../assets/images/retro3.avif"
                alt="Event Invite"
                class="img-w img-h rounded-lg"
              />
              <div class="mt-4">
                <p class="text-sm text-gray-500">Hosted By:</p>
                <p class="text-gray-900 font-medium">Rohit Shetty</p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <!-- Left Subsection 2 -->
            <h3
              class="font-normal font-semibold text-[rgb(19,21,23)] text-[20px] leading-[26px]"
            >
              {{ eventData.name }}
            </h3>
            <div class="mt-2 flex text-sm text-gray-600 space-x-2">
              <div class="rounded text-center border border-gray-300">
                <div class="text-xs uppercase bg-gray-200 p-1 text-gray-700">
                  {{ formattedMonth }}
                </div>
                <div class="text-md font-medium">
                  {{ formattedDay }}
                </div>
              </div>
              <div>
                <div
                  class="font-normal font-medium text-[rgb(19,21,23)] text-[16px] leading-[24px]"
                >
                  {{ formattedFullDate }}
                </div>
                <div
                  class="font-normal text-[rgb(19,21,23,0.64)] text-[14px] leading-[21px]"
                >
                  {{ formattedTimeRange }}
                </div>
              </div>
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-600">
              <div class="flex items-start space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                <div
                  class="font-normal font-medium text-[rgb(19,21,23)] text-[16px] leading-[24px]"
                >
                  Virtual
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="border rounded">
                <div
                  class="font-normal font-medium bg-gray-200 text-gray-700 text-[14px] leading-[21px] p-2"
                >
                  Registration
                </div>
                <div
                  class="flex items-start p-4"
                  v-if="eventData.requireApproval"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-8 h-8 mr-2"
                  >
                    <path
                      d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z"
                    />
                  </svg>

                  <div>
                    <p
                      class="font-normal font-medium text-[rgb(19,21,23)] text-[16px] leading-[24px]"
                    >
                      Approval Required
                    </p>
                    <p
                      class="font-normal text-[rgb(19,21,23,0.64)] text-[14px] leading-[21px]"
                    >
                      Your registration is subject to approval by the host.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  class="flex items-start p-4 font-normal text-[rgb(19,21,23)] text-[16px] leading-[24px]"
                >
                  Welcome! To join the event, please register below.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="link">
          <!-- <div class="inner url animated flex-center spread gap-2">
            <input
              type="text"
              class="border rounded py-2 px-4 w-full"
              value="lu.ma/j56zldfc"
              readonly
            />
          </div> -->
          <div class="inner url animated flex-center spread gap-2">
            <a
              :href="`https://talkto.javascriptfornoobs.com/register?id=${eventData.id}`"
              target="_blank"
              class="flex-center gap-1 min-width-0"
              ><div class="min-width-0 nowrap text-ellipses">Register Here</div>
              <div class="flex-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 17 17 7M7 7h10v10"></path>
                </svg></div></a
            ><button
              class="btn white-text"
              type="button"
              @click="
                copyURL(
                  `https://talkto.javascriptfornoobs.com/?id=${eventData.id}`
                )
              "
            >
              <div class="label">COPY</div>
            </button>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <!-- Right Section -->
        <div class="flex flex-col md:flex-row h-full">
          <div class="w-full pt-4 pl-4 pr-4 flex flex-col h-full">
            <h3
              class="font-semibold text-[rgb(19,21,23)] text-[18px] leading-[22px]"
            >
              When & Where
            </h3>
            <div class="mt-2 flex text-xl text-gray-600 space-x-2">
              <div class="rounded text-center border border-gray-300">
                <div
                  class="font-semibold text-[rgba(19,21,23,0.36)] text-[10px] leading-[15px] bg-gray-200 p-1"
                >
                  {{ formattedMonth }}
                </div>
                <div
                  class="font-normal text-[rgb(19,21,23)] text-[18px] leading-[27px] bg-white"
                >
                  {{ formattedDay }}
                </div>
              </div>
              <div>
                <div
                  class="font-medium text-[rgb(19,21,23)] text-[16px] leading-[24px]"
                >
                  {{ formattedFullDate }}
                </div>
                <div
                  class="font-normal text-[rgba(19,21,23,0.64)] text-[14px] leading-[21px]"
                >
                  {{ formattedTimeRange }}
                </div>
              </div>
            </div>
            <div
              class="mt-4 mb-4 flex flex-col justify-between text-xl text-gray-600 h-full"
            >
              <div class="flex items-start bg-white space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-10 h-10 flex-shrink-0"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                <div class="pt-2">
                  <div
                    class="font-medium text-[rgb(19,21,23)] text-[16px] leading-[24px]"
                  >
                    Virtual
                  </div>
                  <div
                    class="font-normal text-[rgb(213,23,109)] text-[14px] leading-[21px] pt-2"
                  >
                    <a :href="eventData.eventURL" target="_blank">
                      {{ eventData.eventURL }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end space-x-2 mt-auto w-full">
              <button
                class="text-gray px-4 py-2 rounded w-full"
                style="background-color: #f4f4f4"
                @mouseover="hoverone = true"
                @mouseleave="hoverone = false"
                :style="{ backgroundColor: hoverone ? '#D4D4D4' : '#F4F4F4' }"
              >
                Edit Event
              </button>
              <button
                class="text-gray px-4 py-2 rounded w-full"
                style="background-color: #f4f4f4"
                @mouseover="hovertwo = true"
                @mouseleave="hovertwo = false"
                :style="{ backgroundColor: hovertwo ? '#D4D4D4' : '#F4F4F4' }"
              >
                Change Photo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { getEventById } from "../services/eventService";
import { format, parseISO } from "date-fns";

const route = useRoute();
const eventId = route.query.id || "";
const eventData = ref(null); // Initialize as null to check for loading state

const hoverone = ref(false);
const hovertwo = ref(false);

const formattedMonth = computed(() =>
  eventData.value ? format(new Date(eventData.value.startDate), "MMM") : ""
);
const formattedDay = computed(() =>
  eventData.value ? format(new Date(eventData.value.startDate), "d") : ""
);

const formattedFullDate = computed(() =>
  eventData.value
    ? format(new Date(eventData.value.startDate), "eeee, MMMM d")
    : ""
);

const formattedTimeRange = computed(() => {
  if (eventData.value) {
    const startTime = format(parseISO(eventData.value.startDate), "h:mm a");
    const endTime = format(parseISO(eventData.value.endDate), "h:mm a");
    return `${startTime} - ${endTime} CDT`;
  }
  return "";
});

onMounted(async () => {
  try {
    console.log("Fetching event data for eventId:", eventId);
    const response = await getEventById(eventId);
    eventData.value = response;
    console.log("Fetched event data:", eventData.value);
  } catch (error) {
    console.error("Failed to fetch event data:", error);
  }
});

// Function to copy event URL to clipboard
function copyURL(url) {
  navigator.clipboard.writeText(url).then(
    () => {
      console.log("URL copied to clipboard:", url);
    },
    (err) => {
      console.error("Failed to copy URL:", err);
    }
  );
}
</script>

<style scoped>
.img-w {
  width: auto;
}
.img-h {
  height: auto;
}
.wrap-text {
  word-wrap: break-word;
  word-break: break-word;
}
@media (min-width: 768px) {
  .flex-wrap {
    flex-wrap: nowrap;
  }
}

.link {
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba(19, 21, 23, 0.32);
  z-index: 1;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  backdrop-filter: blur(16px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.inner {
  --secondary-button-link-color: rgba(255, 255, 255, 0.48);
  --secondary-button-hover-link-color: white;
}
.animated,
.btn,
.collapsing,
a {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.gap-2 {
  gap: 0.5rem;
}

.spread {
  display: flex;
  justify-content: space-between;
}
.flex-center,
.flex-center-center {
  display: flex;
  align-items: center;
}

.tt-button.link.secondary,
.tt-button.naked.secondary {
  color: rgba(255, 255, 255, 0.48);
}
.tt-button.secondary {
  color: rgba(255, 255, 255, 0.48);
  border-color: #939597;
}
.tt-button.link,
.tt-button.naked {
  background-color: transparent;
}

.tt-button.link {
  padding: 0;
  height: auto;
  border: none;
  border-radius: 0;
  outline-offset: 0.375rem;
}

.tt-button .label {
  line-height: 1;
  margin: -4px 0;
  padding: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.inner a {
  color: rgba(255, 255, 255, 0.8);
}

.border-custom {
  border: 1px solid #e2e8f0;
  border: 0.5rem;
}

.white-text {
  color: rgba(255, 255, 255, 0.8);
}
</style>
