<template>
  <div class="relative" style="background-color: #f8e8dd">
    <div
      class="flex flex-col w-full pt-5 pl-3 rounded text-[#b08968]"
      style="background-color: #f8e8dd"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
        />
      </svg>
      <div
        class="text-xl font-medium pt-2 bg-white rounded cursor-pointer"
        style="background-color: #f8e8dd"
      >
        <div class="text-left">
          <div>{{ selectecdOffsetValue }}</div>
          <div>{{ selectedCityValue }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="isOpen"
      class="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg"
    >
      <input
        v-model="timeRegionZone"
        type="text"
        placeholder="Search"
        class="w-full p-2 border-b"
      />
      <ul class="max-h-60 overflow-y-auto">
        <li
          v-for="timezone in filteredTimezones"
          :key="timezone.name"
          @click="selectTimezone(timezone)"
          class="p-2 hover:bg-gray-100 cursor-pointer flex justify-between"
        >
          <span class="font-medium">{{ timezone.name }}</span>
          <span class="text-sm text-gray-500">{{ timezone.offset }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      search: "",
      selectedTimezone: { name: "Central Time - Chicago", offset: "GMT-05:00" },
      timezones: [
        { name: "Pacific Time - Los Angeles", offset: "GMT-07:00" },
        { name: "Central Time - Chicago", offset: "GMT-05:00" },
        { name: "Eastern Time - Toronto", offset: "GMT-04:00" },
        { name: "Mountain Time - Denver", offset: "GMT-06:00" },
        { name: "Alaska Time - Anchorage", offset: "GMT-08:00" },
        { name: "Hawaii Time - Honolulu", offset: "GMT-10:00" },
        { name: "Greenwich Mean Time - London", offset: "GMT+00:00" },
        { name: "Central European Time - Paris", offset: "GMT+01:00" },
        { name: "Eastern European Time - Athens", offset: "GMT+02:00" },
        { name: "Moscow Time - Moscow", offset: "GMT+03:00" },
        { name: "Gulf Standard Time - Dubai", offset: "GMT+04:00" },
        { name: "Pakistan Standard Time - Karachi", offset: "GMT+05:00" },
        { name: "Indian Standard Time - New Delhi", offset: "GMT+05:30" },
        { name: "Bangladesh Standard Time - Dhaka", offset: "GMT+06:00" },
        { name: "Indochina Time - Bangkok", offset: "GMT+07:00" },
        { name: "China Standard Time - Beijing", offset: "GMT+08:00" },
        { name: "Japan Standard Time - Tokyo", offset: "GMT+09:00" },
        {
          name: "Australian Eastern Standard Time - Sydney",
          offset: "GMT+10:00",
        },
        { name: "New Zealand Standard Time - Auckland", offset: "GMT+12:00" },
      ],
    };
  },
  computed: {
    filteredTimezones() {
      return this.timezones.filter((tz) =>
        tz.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    selectedCityValue() {
      if (this.selectedTimezone) {
        const parts = this.selectedTimezone.name.split(" ");
        const cityIndex = parts.indexOf("-") + 1;
        const cityName = parts.slice(cityIndex).join(" ").trim();
        return cityName;
      }
      return "";
    },
    selectecdOffsetValue() {
      if (this.selectedTimezone) {
        return this.selectedTimezone.offset.trim();
      }
      return "";
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectTimezone(timezone) {
      this.selectedTimezone = timezone;
      this.isOpen = false;
    },
  },
};
</script>
