import { createStore } from "vuex";
import themeModule from "./modules/theme";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    theme: themeModule,
  },
});
