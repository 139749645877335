import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/main.css";
import { createAuth0 } from "@auth0/auth0-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFacebook, faLinkedin, faTwitter);

const app = createApp(App);

app.use(
  createAuth0({
    domain: "talkto.us.auth0.com",
    clientId: "cqgbBOLLbzDh2NdwoTCOELHzjAjleC7L",
    authorizationParams: {
      redirect_uri: "https://talkto.javascriptfornoobs.com/create",
    },
  })
);

app
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
