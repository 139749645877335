<template>
  <div class="flex justify-center h-screen bg-custom-bg">
    <div
      class="w-[45%] h-3/5 bg-transparent flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
      v-if="eventData"
    >
      <div class="w-full">
        <div
          class="flex flex-col md:flex-row p-4 space-y-4 md:space-y-0 md:space-x-8"
        >
          <div class="w-full md:w-1/3">
            <!-- Left Subsection 1 -->
            <div>
              <img
                src="../assets/images/retro3.avif"
                alt="Event Invite"
                class="img-w img-h rounded-lg"
              />
              <div class="mt-4">
                <p class="text-sm text-gray-500">Hosted By:</p>
                <p class="text-gray-900 font-medium">Rohit Shetty</p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <!-- Left Subsection 2 -->
            <h3
              class="font-normal font-semibold text-[rgb(12,0,104)] text-[48px] leading-[55px]"
            >
              {{ eventData.name }}
            </h3>
            <div class="mt-8 flex text-sm text-gray-600 space-x-2">
              <div class="rounded text-center border border-gray-300">
                <div
                  class="font-normal font-semibold text-[rgba(35,63,148,0.8)] text-[8px] leading-[12px] uppercase bg-gray-200 p-1"
                >
                  {{ formattedMonth }}
                </div>
                <div
                  class="font-normal font-medium text-[rgba(35,63,148,0.8)] text-[16px] leading-[24px]"
                >
                  {{ formattedDay }}
                </div>
              </div>
              <div>
                <div
                  class="font-normal font-medium text-[rgb(12,0,104)] text-[16px] leading-[24px]"
                >
                  {{ formattedFullDate }}
                </div>
                <div
                  class="font-normal text-[rgba(35,63,148,0.8)] text-[14px] leading-[21px]"
                >
                  {{ formattedTimeRange }}
                </div>
              </div>
            </div>
            <div class="mt-6 flex items-center text-sm text-gray-600">
              <div class="flex items-start space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                <div
                  class="font-normal font-medium text-[rgb(12,0,104)] text-[16px] leading-[24px]"
                >
                  Virtual
                </div>
              </div>
            </div>
            <div class="mt-6">
              <div class="border rounded-xl">
                <div
                  class="font-normal font-medium text-[rgba(35,63,148,0.8)] text-[14px] leading-[21px] bg-custom-blue p-2"
                >
                  Registration
                </div>
                <div
                  class="flex items-start p-4"
                  style="background-color: #f2f8ff"
                  v-if="eventData.requireApproval"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-8 h-8 mr-2"
                  >
                    <path
                      d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z"
                    />
                  </svg>

                  <div>
                    <p
                      class="font-normal font-medium text-[rgb(12,0,104)] text-[16px] leading-[24px]"
                    >
                      Approval Required
                    </p>
                    <p
                      class="font-normal text-[rgba(35,63,148,0.8)] text-[14px] leading-[21px]"
                    >
                      Your registration is subject to approval by the host.
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  class="flex flex-col items-start p-4 text-sm font-bold text-gray-900"
                  style="background-color: #f2f8ff"
                >
                  <div
                    class="font-normal text-[rgb(12,0,104)] text-[16px] leading-[24px]"
                  >
                    Welcome! To join the event, please register below.
                  </div>
                  <div class="w-full mt-4">
                    <button
                      class="font-normal text-[rgb(12,0,104)] text-[16px] leading-[24px] bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-full"
                    >
                      One-Click Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <div>
                <div
                  class="font-normal font-medium text-[rgba(35,63,148,0.8)] text-[14px] leading-[21px] p-2"
                >
                  About Event
                </div>
                <hr />
                <div class="flex items-start p-4">
                  <div>
                    <p
                      class="font-normal font-medium text-[rgb(12,0,104)] text-[16px] leading-[24px]"
                    >
                      {{
                        eventData.description
                          ? eventData.description
                          : "No Description"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { getEventById } from "../services/eventService";
import { format, parseISO } from "date-fns";

const route = useRoute();
const eventId = route.query.id || "";
const eventData = ref(null); // Initialize as null to check for loading state

const formattedMonth = computed(() =>
  eventData.value ? format(new Date(eventData.value.startDate), "MMM") : ""
);
const formattedDay = computed(() =>
  eventData.value ? format(new Date(eventData.value.startDate), "d") : ""
);

const formattedFullDate = computed(() =>
  eventData.value
    ? format(new Date(eventData.value.startDate), "eeee, MMMM d")
    : ""
);

const formattedTimeRange = computed(() => {
  if (eventData.value) {
    const startTime = format(parseISO(eventData.value.startDate), "h:mm a");
    const endTime = format(parseISO(eventData.value.endDate), "h:mm a");
    return `${startTime} - ${endTime} CDT`;
  }
  return "";
});

onMounted(async () => {
  try {
    console.log("Fetching event data for eventId:", eventId);
    const response = await getEventById(eventId);
    eventData.value = response;
    console.log("Fetched event data:", eventData.value);
  } catch (error) {
    console.error("Failed to fetch event data:", error);
  }
});
</script>

<style scoped>
.bg-custom-bg {
  background-color: #ecf5ff;
}
</style>
