<template>
  <div class="flex justify-center min-h-screen bg-gray-100">
    <div
      class="w-[40%] h-3/5 p-6 flex flex-col space-y-16"
      v-if="eventsList && eventsList.length"
    >
      <div class="w-full flex pr-4 font-bold text-black text-5xl">Events</div>
      <div
        v-for="item in eventsList"
        :key="item.key"
        class="w-full flex flex-col space-y-4"
      >
        <div
          class="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
        >
          <div class="w-full md:w-1/5 p-4">
            <div class="font-bold text-black">
              {{ format(new Date(item.startDate), "MMM dd") }}
            </div>
            <div class="text-gray-500">
              {{ format(new Date(item.startDate), "EEEE") }}
            </div>
          </div>
          <div class="w-full md:w-4/5 bg-white shadow-2xl rounded-2xl p-4 flex">
            <div class="w-2/3">
              <span class="text-gray-500"
                >{{ format(parseISO(item.startDate), "h:mm a") }}
              </span>
              <!-- <span class="text-lg font-medium" style="color: #e0bd6a"
                >7:30 PM GMT+5:30</span
              > -->
              <div class="mt-2">
                <h3 class="text-xl font-semibold">{{ item.name }}</h3>
                <div
                  class="mt-4 mb-4 flex flex-col justify-between text-xl text-gray-600 h-full"
                >
                  <div class="flex items-start bg-white space-x-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-7 h-7 flex-shrink-0"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                    <div class="text-gray-500">
                      Virtual
                      <!-- <div class="text-sm text-gray-500 pt-2">
                        <a :href="item.value.eventURL" target="_blank">Go to event</a>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="mt-4 mb-4 flex flex-col justify-between text-xl text-gray-600 h-full"
                >
                  <div class="flex items-start bg-white space-x-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                      />
                    </svg>

                    <div class="text-gray-500">No Guests</div>
                  </div>
                </div>
                <div class="mt-8">
                  <button
                    class="text-gray px-4 py-2 rounded flex items-center"
                    style="background-color: #f4f4f4"
                    @mouseover="hoverone = true"
                    @mouseleave="hoverone = false"
                    :style="{
                      backgroundColor: hoverone ? '#D4D4D4' : '#F4F4F4',
                    }"
                    @click="manageEvent(item.id)"
                  >
                    Manage Event
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6 ml-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-1/3 flex justify-end">
              <img
                src="../assets/images/retro3.avif"
                alt="Event Image"
                class="img-w img-h rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getAllEvents } from "../services/eventService";
import { ref, onMounted, computed } from "vue";
import { format, parseISO } from "date-fns";
import { useRouter } from "vue-router";

const router = useRouter();
const eventsList = ref([]); // Initialize as an empty array

onMounted(async () => {
  try {
    const response = await getAllEvents();
    if (Array.isArray(response)) {
      eventsList.value = response;
      console.log(
        "Raw eventsList value:",
        JSON.stringify(eventsList.value, null, 2)
      );
    } else {
      console.error("Expected an array but got:", response);
    }
  } catch (error) {
    console.error("Failed to fetch event data:", error);
  }
});

function manageEvent(eventId) {
  // Navigate to ManageEventView page with the event ID
  router.push({ name: "manage-event", query: { id: eventId } });
}
</script>

<style scoped>
.img-w {
  width: 150px;
}
.img-h {
  height: 150px;
}
</style>
