<template>
  <div
    v-if="isOpen"
    class="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center modal-background"
  >
    <div class="bg-white rounded-lg p-6 w-80">
      <h2 class="text-xl font-semibold mb-4">Max Capacity</h2>
      <p class="text-sm text-gray-600 mb-4">
        Auto-close registration when the capacity is reached. Only approved
        guests count toward the cap.
      </p>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 mb-1"
          >Capacity</label
        >
        <input
          v-model="localCapacity"
          type="number"
          class="w-full p-2 border rounded"
          placeholder="50"
        />
      </div>
      <div class="flex items-center justify-between mb-4">
        <span class="text-sm font-medium text-gray-700"
          >Over-Capacity Waitlist</span
        >
        <label class="switch">
          <input type="checkbox" v-model="overCapacityWaitlist" />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="flex justify-between">
        <button
          @click="applyCapacityLimit"
          class="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700"
        >
          Set Limit
        </button>
        <button
          @click="clearCapacityLimit"
          class="text-gray-600 px-4 py-2 rounded hover:bg-gray-100"
        >
          Remove Limit
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";

const props = defineProps({
  isOpen: Boolean,
  initialCapacity: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["setCapacity", "update:isOpen", "removeCapacity"]);

const localCapacity = ref(props.initialCapacity);
const overCapacityWaitlist = ref(false);

watch(
  () => props.initialCapacity,
  (newVal) => {
    localCapacity.value = newVal;
  }
);

function applyCapacityLimit() {
  emit("setCapacity", localCapacity.value, overCapacityWaitlist.value);
  emit("update:isOpen", false);
}

function clearCapacityLimit() {
  emit("removeCapacity");
  emit("update:isOpen", false);
}
</script>

<style scoped>
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: grayscale(0.3);
  -webkit-backdrop-filter: grayscale(0.3);
}
</style>
