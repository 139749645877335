<template>
  <div
    class="flex justify-center h-screen bg-custom-bg bg-gradient-to-tr"
    :class="themeClass"
  >
    <div class="flex rounded-lg w-[60%] h-3/5">
      <ImageUploadArea />
      <div class="w-2/3 ml-10">
        <div class="mb-4">
          <textarea
            v-model="name"
            class="w-full text-6xl font-bold placeholder-gray-200 focus:outline-none bg-transparent resize-none transition-colors duration-200 hover:placeholder-gray-400 text-[#480000]"
            placeholder="Event Name"
            spellcheck="false"
            autocapitalize="words"
            maxlength="140"
            autofocus
            @input="adjustHeight($event)"
          ></textarea>
        </div>
        <div class="mb-6">
          <div class="grid grid-cols-10 gap-x-5">
            <!-- Left Part (75%) -->
            <div
              class="col-span-7 p-4 rounded-lg shadow-md"
              style="background-color: #f8e8dd"
            >
              <div class="flex justify-between items-center">
                <div class="flex items-center">
                  <div class="w-3 h-3 bg-[#b08968] rounded-full mr-2"></div>
                  <span class="text-xl font-medium text-[#b08968]">Start</span>
                </div>
                <div class="flex items-center">
                  <input
                    type="date"
                    v-model="startDate"
                    :min="minStartDate"
                    class="border cursor-pointer rounded p-2 text-lg bg-transparent h-12 text-[#b08968] focus:outline-none"
                    :style="{
                      backgroundColor: hoveredStartDate ? '#F0DCD3' : '#f1dfd4',
                    }"
                    @mouseover="hoveredStartDate = true"
                    @mouseleave="hoveredStartDate = false"
                  />
                  <select
                    v-model="startTime"
                    class="border cursor-pointer rounded p-2 text-lg bg-transparent ml-2 h-12 text-[#b08968] focus:outline-none overflow-hidden"
                    style="
                      background-color: #f1dfd4;
                      -ms-overflow-style: none;
                      scrollbar-width: none;
                    "
                  >
                    <option
                      v-for="time in filteredStartTimes"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="dashed-line"></div>
              <div class="flex justify-between items-start">
                <div class="flex items-center">
                  <div
                    class="w-3 h-3 border-2 border-[#b08968] rounded-full mr-2"
                  ></div>
                  <span class="text-xl font-medium text-[#b08968]">End</span>
                </div>
                <div class="flex items-center">
                  <input
                    type="date"
                    v-model="endDate"
                    :min="minEndDate"
                    class="border cursor-pointer rounded p-2 text-lg bg-transparent h-12 text-[#b08968] focus:outline-none"
                    :style="{
                      backgroundColor: hoveredEndDate ? '#F0DCD3' : '#f1dfd4',
                    }"
                    @mouseover="hoveredEndDate = true"
                    @mouseleave="hoveredEndDate = false"
                  />
                  <select
                    v-model="endTime"
                    class="border cursor-pointer rounded p-2 text-lg bg-transparent ml-2 h-12 text-[#b08968] focus:outline-none overflow-hidden"
                    style="
                      background-color: #f1dfd4;
                      -ms-overflow-style: none;
                      scrollbar-width: none;
                    "
                  >
                    <option
                      v-for="time in filteredEndTimes"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- Right Part (25%) -->
            <div
              class="col-span-3 rounded-lg shadow-md"
              style="background-color: #f8e8dd"
            >
              <TimezoneSelector />
            </div>
          </div>
        </div>
        <div
          class="mb-6 rounded p-4 text-lg flex flex-col gap-4 cursor-pointer w-full text-xl placeholder-gray-200 focus:outline-none text-[#b08968]"
          :style="{ backgroundColor: hoveredLocation ? '#F0DCD3' : '#f8e8dd' }"
          @click="openModal('Event Location')"
          @mouseover="hoveredLocation = true"
          @mouseleave="hoveredLocation = false"
        >
          <div class="flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>

            <div class="ml-3">
              {{ eventURL ? "Event Location" : "Add Event Location" }}
            </div>
          </div>
          <div class="flex items-start ml-9" v-if="eventURL">
            {{ eventURL }}
          </div>
        </div>
        <div
          class="mb-6 rounded p-4 text-lg flex flex-col gap-4 cursor-pointer w-full text-xl placeholder-gray-200 focus:outline-none text-[#b08968]"
          @click="openModal('Event Description')"
          :style="{
            backgroundColor: hoveredDescription ? '#F0DCD3' : '#f8e8dd',
          }"
          @mouseover="hoveredDescription = true"
          @mouseleave="hoveredDescription = false"
        >
          <div class="flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
            <div class="ml-3">
              {{
                eventDescription ? "Event Description" : "Add Event Description"
              }}
            </div>
          </div>
          <div class="flex items-start ml-9" v-if="eventDescription">
            {{ eventDescription }}
          </div>
        </div>
        <div
          class="mb-6 rounded p-4 text-lg flex flex-col gap-4 w-full text-xl placeholder-gray-200 focus:outline-none text-[#b08968]"
          style="background-color: #f8e8dd"
        >
          <div class="flex justify-between items-start">
            <div class="flex items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                />
              </svg>

              <div class="ml-3">Tickets</div>
            </div>
            <div>Free</div>
          </div>
          <div class="flex justify-between items-start">
            <div class="flex items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                />
              </svg>

              <div class="ml-3">Require Approval</div>
            </div>
            <div>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="requireApproval"
                  value=""
                  class="sr-only peer"
                />
                <div
                  class="relative w-11 h-6 bg-orange-300 peer-focus:outline-none rounded-full peer dark:bg-orange-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-600"
                ></div>
              </label>
            </div>
          </div>
          <div class="flex justify-between items-start">
            <div class="flex items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
                />
              </svg>

              <div class="ml-3">Capacity</div>
            </div>
            <div>
              <span class="mr-2">{{
                capacity === "unlimited" ? "Unlimited" : capacity
              }}</span>
              <button
                @click="openCapacityModal"
                class="text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <button
          @click="createEvent"
          class="mt-6 w-full bg-orange-600 text-white text-lg py-4 rounded-lg hover:bg-orange-700"
        >
          Create Event
        </button>
        <div
          v-if="isModalOpen"
          class="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
          style="
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: grayscale(0.3);
            -webkit-backdrop-filter: grayscale(0.3);
          "
        >
          <div class="bg-white rounded-3xl flex flex-col w-1/4">
            <div
              class="border-b pl-5 pb-2 pt-2 mb-2 bg-gray-100 flex justify-start"
            >
              <h2 class="text-lg font-bold">{{ modalHeader }}</h2>
            </div>
            <textarea
              v-model="modalContent"
              class="w-full h-64 p-2 flex-grow resize-none focus:outline-none bg-transparent"
            ></textarea>
            <div class="mt-2 pr-5 pb-2 pt-2 bg-gray-100 flex justify-end">
              <button
                class="px-4 py-2 bg-black text-white rounded"
                @click="closeModal"
              >
                Done
              </button>
            </div>
          </div>
        </div>

        <CapacityModal
          v-model:isOpen="isCapacityModalOpen"
          :initialCapacity="parseInt(capacity)"
          @setCapacity="setCapacity"
          @removeCapacity="removeCapacity"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import ImageUploadArea from "@/components/ImageUploadArea.vue";
import TimezoneSelector from "@/components/TimezoneSelector.vue";
import CapacityModal from "@/components/CapacityModal.vue";
import { useStore } from "vuex";
import { createEvent as createEventService } from "../services/eventService";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const name = ref("");
const startDate = ref<Date | null>(null);
const startTime = ref("");
const endDate = ref("");
const endTime = ref("");
const timeRegionZone = ref("");
const eventURL = ref("");
const eventDescription = ref("");
const requireApproval = ref(false);
const isModalOpen = ref(false);
const description = ref("");
const modalHeader = ref("");
const modalContent = ref("");
const capacity = ref("unlimited");
const isCapacityModalOpen = ref(false);
const hoveredLocation = ref(false);
const hoveredDescription = ref(false);
const hoveredStartDate = ref(false);
const hoveredEndDate = ref(false);

const currentTheme = computed(() => store.getters["theme/currentTheme"]);

const themeClass = computed(() => ({
  "bg-white text-black": currentTheme.value === "light",
  "bg-black text-white": currentTheme.value === "dark",
}));

const toggleTheme = () => {
  store.dispatch("theme/toggleTheme");
};

watchEffect(() => {
  console.log(currentTheme.value);
});

const times = ref(generateTimes());

function generateTimes() {
  const times = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = (i === 0 || i === 12 ? 12 : i % 12)
        .toString()
        .padStart(2, "0");
      const minute = j === 0 ? "00" : j;
      const period = i < 12 ? "AM" : "PM";
      times.push(`${hour}:${minute} ${period}`);
    }
  }
  return times;
}

function isToday(date: Date) {
  if (!(date instanceof Date)) {
    return false;
  }
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const minStartDate = computed(() => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Assuming startDate is reactive
});

const minEndDate = computed(() => {
  return startDate.value || minStartDate.value;
});

const filteredStartTimes = computed(() => {
  if (!isToday(startDate.value as Date)) {
    return times.value;
  }

  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const currentPeriod = currentHour < 12 ? "AM" : "PM";
  const formattedCurrentHour = (
    currentHour === 0 || currentHour === 12 ? 12 : currentHour % 12
  )
    .toString()
    .padStart(2, "0");
  const formattedCurrentMinute = currentMinute < 30 ? "00" : "30";
  const formattedCurrentTime = `${formattedCurrentHour}:${formattedCurrentMinute} ${currentPeriod}`;

  return times.value.filter((time) => {
    // Convert both current time and the time from the array to 24-hour format for comparison
    const timeIn24hr = convertTo24HourFormat(time);
    const currentTimeIn24hr = convertTo24HourFormat(formattedCurrentTime);
    return timeIn24hr > currentTimeIn24hr;
  });
});

function convertTo24HourFormat(time: any) {
  const [timePart, period] = time.split(" ");
  let [hours, minutes] = timePart.split(":");
  if (period === "PM" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  } else if (period === "AM" && hours === "12") {
    hours = "00";
  }
  return `${hours}:${minutes}`;
}

const filteredEndTimes = computed(() => {
  if (!startTime.value) {
    return filteredStartTimes.value; // If no start time is selected, return all times
  }

  // Convert start time to 24-hour format for comparison
  const startTimeIn24hr = convertTo24HourFormat(startTime.value);

  return times.value.filter((time) => {
    const timeIn24hr = convertTo24HourFormat(time);
    return timeIn24hr > startTimeIn24hr;
  });
});

function openModal(header: string) {
  isModalOpen.value = true;
  modalHeader.value = header;
  modalContent.value =
    header === "Event Location" ? eventURL.value : eventDescription.value;
}

function closeModal() {
  if (modalHeader.value === "Event Location") {
    eventURL.value = modalContent.value;
  } else if (modalHeader.value === "Event Description") {
    eventDescription.value = modalContent.value;
  }
  modalContent.value = "";
  isModalOpen.value = false;
}

async function createEvent() {
  console.log("Event Name:", name.value);
  console.log("Start Date:", startDate.value, "Start Time:", startTime.value);
  console.log("End Date:", endDate.value, "End Time:", endTime.value);
  console.log("Event Location:", eventURL.value);
  console.log("Event Description:", eventDescription.value);
  console.log("Require Approval:", requireApproval.value);
  console.log("Capacity:", capacity.value);
  console.log("timeRegionZone:", timeRegionZone.value);

  const formattedStartTime = convertTo24HourFormat(startTime.value);
  const formattedEndTime = convertTo24HourFormat(endTime.value);

  const startDateTime = new Date(
    `${startDate.value}T${formattedStartTime}`
  ).toISOString();

  const endDateTime = new Date(
    `${endDate.value}T${formattedEndTime}`
  ).toISOString();

  const eventData = {
    ownerId: generateUUID(),
    name: name.value,
    startDate: startDateTime,
    endDate: endDateTime,
    description: eventDescription.value,
    eventURL: eventURL.value,
    eventMedia: "",
    ticketsType: "Free",
    requireApproval: requireApproval.value,
    capacity: capacity.value ? Number(capacity.value) : 50,
    //timeRegionZone: timeRegionZone.value,
  };

  try {
    const response = await createEventService(eventData);
    console.log("Event created successfully:", response);
    // Handle success
    const eventId = response.id;

    // Navigate to ManageEventView page with the event ID
    router.push({ name: "manage-event", query: { id: eventId } });
  } catch (error) {
    console.error("Failed to create event:", error);
    // Handle error (e.g., show an error message)
  }
}

function adjustHeight(event: any) {
  const element = event.target;
  element.style.height = "auto"; // Reset height to recalculate
  element.style.height = element.scrollHeight + "px"; // Set new height
}

const openCapacityModal = () => {
  isCapacityModalOpen.value = true;
};

const setCapacity = (newCapacity: string, hasWaitlist: boolean) => {
  capacity.value = isNaN(Number(newCapacity)) ? "unlimited" : newCapacity;
};

const removeCapacity = () => {
  capacity.value = "unlimited";
};
</script>
<style scoped>
.bg-custom-bg {
  background-color: #fff0e6;
}
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}

textarea::placeholder {
  color: #d3b09f; /* Tailwind's placeholder-gray-500 */
  transition: color 0.2s;
}
textarea:hover::placeholder {
  color: #480000;
}

.dashed-line {
  border-left: 2px dashed #b3b3b3;
  height: 25px;
  margin: 0 0 0 0.4rem;
}

select::-webkit-scrollbar {
  display: none;
}

.checked-toggle {
  background-color: #c24000 !important;
}
</style>
