<template>
  <header :class="bgClass" class="text-white mb-10">
    <nav
      class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div class="flex lg:flex-1"></div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a
          v-for="item in navItems"
          :key="item.name"
          :href="item.href"
          class="placeholder-gray-200 transition-colors duration-200 hover:placeholder-gray-400 text-[#480000] px-3 py-2 rounded-md text-sm font-medium flex items-center"
        >
          <component :is="item.icon" class="h-5 w-5 mr-2" />
          <span
            class="font-normal font-medium text-[rgb(19, 21, 23)] text-[14px] leading-[21px]"
          >
            {{ item.name }}
          </span>
        </a>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a
          href="#"
          @click="handleLogin"
          class="text-sm font-semibold leading-6 text-gray-900"
          v-if="!isAuthenticated"
          >Log in <span aria-hidden="true">&rarr;</span></a
        >
        <a
          href="#"
          @click="handleLogout"
          class="text-sm font-semibold leading-6 text-gray-900"
          v-if="isAuthenticated"
          >Logout <span aria-hidden="true">&rarr;</span></a
        >
      </div>
    </nav>
  </header>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { TicketIcon, EyeIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";
import { defineProps } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";

const props = defineProps<{
  bgClass: string;
}>();

const { loginWithRedirect } = useAuth0();

const handleLogin = () => {
  loginWithRedirect({
    appState: {
      target: "/create",
    },
  });
};

const { logout } = useAuth0();

const handleLogout = () =>
  logout({
    logoutParams: {
      returnTo: "https://talkto.javascriptfornoobs.com/",
    },
  });

const { isAuthenticated } = useAuth0();

const navItems = ref([
  { name: "Events", href: "/list", icon: TicketIcon, current: true },
  { name: "Discover", href: "#", icon: EyeIcon, current: false },
  {
    name: "Create Event",
    href: "/create",
    icon: PlusCircleIcon,
    current: false,
  },
]);
</script>
