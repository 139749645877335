<template>
  <div :class="bgClass" class="h-screen">
    <Header :bgClass="bgClass" />
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import Header from "@/components/HeaderNav.vue";

const route = useRoute();

const bgClass = computed(() => {
  switch (route.path) {
    case "/register":
      return "bg-register-event";
    case "/list":
      return "bg-list-events";
    case "/manage":
      return "bg-manage-event";
    default:
      return "bg-custom-bg";
  }
});
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Apple Color Emoji", Inter,
    Roboto, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.bg-custom-bg {
  background-color: #fff0e6;
}

.bg-manage-event {
  background-color: #f4f5f6;
}

.bg-list-events {
  background-color: #f4f5f6;
}

.bg-register-event {
  background-color: #ecf5ff;
}
</style>
