import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CreateEventView from "../views/CreateEventView.vue";
import ManageEventView from "../views/ManageEventView.vue";
import ListEventsView from "../views/ListEventsView.vue";
import RegisterEventView from "../views/RegisterEventView.vue";
import HomeView from "../views/HomeView.vue";
import EditEventView from "../views/EditEventView.vue";
import { authGuard } from "@auth0/auth0-vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/create",
    name: "create-event",
    component: CreateEventView,
    beforeEnter: authGuard,
  },
  {
    path: "/edit",
    name: "edit-event",
    component: EditEventView,
    beforeEnter: authGuard,
  },
  {
    path: "/manage",
    name: "manage-event",
    component: ManageEventView,
  },
  {
    path: "/list",
    name: "list-events",
    component: ListEventsView,
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: "register-event",
    component: RegisterEventView,
    beforeEnter: authGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
