<template>
  <div class="relative w-[400px] h-[400px] mx-auto">
    <img
      :src="imageUrl"
      alt="Profile Image"
      class="w-full h-full rounded-lg object-cover"
    />
    <label
      for="imageUpload"
      class="absolute bottom-2 right-2 bg-gray-800 text-white p-2 rounded-full cursor-pointer"
    >
      <input
        type="file"
        id="imageUpload"
        class="hidden"
        @change="onImageChange"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-6 h-6"
      >
        <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
        <path
          fill-rule="evenodd"
          d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clip-rule="evenodd"
        />
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: require("@/assets/images/retro3.avif"), // Default image
    };
  },
  methods: {
    onImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style scoped>
.profile-image {
  width: 330px;
  height: 330px;
  border-radius: 8px; /* Adjust the border radius as needed */
}
</style>
