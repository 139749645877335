export default {
  state: () => ({
    theme: "light", // default theme
  }),
  mutations: {
    setTheme(state: { theme: string }, theme: string) {
      state.theme = theme;
    },
  },
  actions: {
    toggleTheme({ commit, state }: { commit: any; state: { theme: string } }) {
      const newTheme = state.theme === "light" ? "dark" : "light";
      commit("setTheme", newTheme);
    },
  },
  getters: {
    currentTheme: (state: { theme: string }) => state.theme,
  },
};
