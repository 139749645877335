import axios from "axios";
import { EventData } from "../models/EventData";
import { ApiResponse } from "../models/ApiResponse";

const API_BASE_URL =
  "https://elb-ecs-talkto-public-65676735.us-east-1.elb.amazonaws.com/api";

// Function to create an event
export const createEvent = async (
  eventData: EventData
): Promise<ApiResponse> => {
  try {
    const response = await axios.post<ApiResponse>(
      `${API_BASE_URL}/EventManagement`,
      eventData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating event:", error);
    throw error;
  }
};

// Function to get event details by ID
export const getEventById = async (id: string): Promise<ApiResponse> => {
  try {
    const url = `${API_BASE_URL}/EventManagement/${id}`;
    console.log("Calling URL:", url); // Debug log to verify the URL
    const response = await axios.get<ApiResponse>(
      `${API_BASE_URL}/EventManagement/${id}`
    );

    console.log("Response data:", response.data); // Debug log to verify the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching event:", error);
    throw error;
  }
};

export const getAllEvents = async (): Promise<ApiResponse> => {
  try {
    const url = `${API_BASE_URL}/EventManagement`;
    console.log("Calling URL:", url); // Debug log to verify the URL
    const response = await axios.get<ApiResponse>(
      `${API_BASE_URL}/EventManagement`
    );

    console.log("Response data:", response.data); // Debug log to verify the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching event:", error);
    throw error;
  }
};
